/* global grecaptcha */
import React, { Component } from "react";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"
import { LoadingOutlined, CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { Spin, Collapse, Checkbox } from 'antd'; 

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

export class Util extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            requestFailed: false,
            data: null,
            token: "",
        };
    };

    componentDidMount() {
        this.getData();
    }; 

    componentDidUpdate() {
    
    };

    getData = () => {
        fetch('https://smartpay.tj/subapi/payler/safecity/util/' + this.props.params.vinNumber, 
        { 
            method: "GET", 
            headers : {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
                'x-app-token': API_KEY(),
            }
        })
    
        .then((response) => {
          if(response.status === 200){
              return response.json();     
          }else {
              this.setState({ requestFailed: true })
              window.location.href = 'https://smartpay.tj/'
          }
        })
            .then((responseJson) => {
              this.setState({ data : responseJson, 
                isLoaded: true });
            });
      };

    handleVerify = async () => {
        try {
          const token = await grecaptcha.execute("6LdbjS0qAAAAAFrFSN5HHvRkffPfeG-078Hbadb_", { action: "submit" });
          //console.log("Полученный токен:", token);
          this.setState({ token }, this.submitForm);
        } catch (error) {
          //console.error("Ошибка при вызове ReCaptcha:", error);
        }
      };

    

render () {
    const { isLoaded, data, } = this.state;
    const { translation } = this.props;
    
return (
    <div className="flex justify-center w-full text-center pt-4">{!isLoaded ?
      <div className="flex w-full h-[80vh] justify-center items-center">
        <Spin size="large" indicator={antIcon} />
      </div> : <>
      
      <div className="sm:w-full sm:px-2 md:w-[40%]">
          {data && <div className="w-full flex justify-center font-bold text-[18px]">{data[0].vehicle}</div>
          }

            <div className="w-full flex justify-center font-semibold text-slate-600 text-[18px]">{translation('vin_code')}</div>
            <div className="w-full flex justify-center py-4">
                <div className="rounded-[5px] border-[2px] border-black px-4">    
                    <div className="text-[24px] font-bold tracking-wider">{this.props.params.vinNumber}</div>
                </div>
            </div>
            
              
              {data ? data.map((item, index)=>(
                data.length===1 && item.group==='704. Сиёҳшиша' ?
                <>
                <div className="rounded-[5px] border-[1px] border-black p-4 mb-4">
                  <div className="flex">

                    <CheckCircleFilled style={{color:"#08c20b", fontSize: '42px'}} />
                    <span className="inline-block align-middle m-auto px-4 font-semibold text-slate-600">{`${translation('tonirovka')} - ${item.service}`}</span>
                  </div>
                  <div className="flex pt-2">
                      <div>{translation('INVOICE')}</div>
                      <div className="px-2"><a className="text-sky-700 font-semibold" href={`https://smartpay.tj/invoice/${item.invoice_number}`}>{item.invoice_number}</a></div>
              
                  </div>
                  <div className="flex pt-2">
                    <div>{translation('deadline')}</div>
                    <div className="px-2">{item.deadline}</div>
                  </div>
                </div>
                <div className="rounded-[5px] border-[1px] border-black p-4 mb-4">
                  <div className="flex mt-2">
                    <WarningFilled style={{color:"#f0a211", fontSize: '42px'}}/><div className="px-4">{translation('no_util')}</div>
                  </div>
                </div>
                </>
                
                : data.length===1 && item.group==='703. Нобутсозӣ (утилизатсия)' ?
                <>
                <div className="rounded-[5px] border-[1px] border-black p-4 mb-4">
                <div className="flex">
                  <WarningFilled style={{color:"#f0a211", fontSize: '42px'}}/><div className="px-4">{translation('no_tonirovka')}</div>
                </div>
                </div>
                <div className="rounded-[5px] border-[1px] border-black p-4 mb-4">
                  <div className="flex">

                    <CheckCircleFilled style={{color:"#08c20b", fontSize: '42px'}} />
                    <span className="inline-block align-middle m-auto px-4 font-semibold text-slate-600">{ `${translation('util')}`}</span>
                  </div>
                  <div className="flex pt-2">
                      <div>{translation('INVOICE')}</div>
                      <div className="px-2"><a className="text-sky-700 font-semibold" href={`https://smartpay.tj/invoice/${item.invoice_number}`}>{item.invoice_number}</a></div>
              
                  </div>
                </div>
                </>
                : 
                <>
                  <div className="rounded-[5px] border-[1px] border-black p-4 mb-4">
                  <div className="flex">

                    <CheckCircleFilled style={{color:"#08c20b", fontSize: '42px'}} />
                    <span className="inline-block align-middle m-auto px-4 font-semibold text-slate-600">{item.group==='704. Сиёҳшиша' ? `${translation('tonirovka')} - ${item.service}` : `${translation('util')}`}</span>
                  </div>
                  <div className="flex pt-2">
                      <div>{translation('INVOICE')}</div>
                      <div className="px-2"><a className="text-sky-700 font-semibold" href={`https://smartpay.tj/invoice/${item.invoice_number}`}>{item.invoice_number}</a></div>
              
                  </div>
                  {item.group==='704. Сиёҳшиша' &&
                  <div className="flex pt-2">
                    <div>{translation('deadline')}</div>
                    <div className="px-2">{item.deadline}</div>
                  </div>
                  }
                </div>
                </>
              ))
              :<><div className="rounded-[5px] border-[1px] border-black p-4 mb-4">
              <div className="flex">
                <WarningFilled style={{color:"#f0a211", fontSize: '42px'}}/><div className="px-4">{translation('no_tonirovka')}</div>
              </div>
              </div>
              <div className="rounded-[5px] border-[1px] border-black p-4 mb-4">
              <div className="flex mt-2">
                <WarningFilled style={{color:"#f0a211", fontSize: '42px'}}/><div className="px-4">{translation('no_util')}</div>
              </div>
              </div></>}
              
            <div>
            
            </div>        
            <div className="px-2">
            
            </div>
      </div>
    
    </>
    }</div>
);    
};
}

export default withRouter(Util);