import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { API_KEY } from "./components/MainPage/GlobalFunctions";
import { deviceDetect } from "react-device-detect";
//import InvoiceForm from "./components/MainPage/Invoice/InvoiceForm";
import ReceiptF from "./components/MainPage/Receipt/Receipt";
import Receipt from "./components/MobilePage/Receipt";
import Notfound from "./components/MobilePage/Notfound";
import MainLayout from "./components/MainPage/MainLayout";
import ReserveLayout from "./components/MainPage/ReserveLayout";
//import { MainHome } from "./components/MainPage/Home";
//import Nikestore from "./components/MainPage/Nikestore";
//import Cafedemo from "./components/MainPage/Cafedemo";
//import Egovdemo from "./components/MainPage/Egovdemo";
import ReceiptForm from "./components/MainPage/Invoice/ReceiptForm";
import Government from "./components/pages/Government";
import Buziness from "./components/pages/Buziness";
import MobileInvoiceForm from "./components/MainPage/Invoice/MobileInvoiceForm";
import SecondLayout from "./components/MainPage/SecondLayout";
import Qr from "./components/MainPage/Qr";
import CsInvoice from "./components/MainPage/Invoice/CsInvoice";
import ServiceForm from "./components/MainPage/Service/ServiceForm";
import GazpromNeft from "./components/MainPage/Service/GazpromNeft";
import Cafedemo from "./components/MainPage/Cafedemo";
import Ticket from "./components/MainPage/Service/Ticket";
import Rexgen from "./components/MainPage/Service/Rexgen";
/* import RexgenEvidence from "./components/MainPage/Service/RexgenEvidence";
import RexgenProof from "./components/MainPage/Service/RexgenProof"; */
import Util from "./components/MainPage/Service/Util";

export default class App extends React.Component {
  componentDidMount() {
    const device = JSON.parse(JSON.stringify(deviceDetect()));
    fetch(`https://smartpay.tj/api/v1/common/stat`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
          'x-app-token': API_KEY(),
        },
        body: JSON.stringify({
          'api_url': window.location.href,
          'device': device,
        })
      })
  }

  render() {
    return (
      <>
        <div className="full-height">
          <Routes>
            <Route path="/" element={<ReserveLayout />}>
              <Route path="invoice/:invoiceId" element={ <MobileInvoiceForm/>} />
              <Route path="ticket/:merchantId/:catId/:serviceId" element={<Ticket />}/>
              <Route path="service/:merchantId/:catId" element={ <ServiceForm />} />
              <Route path='util/:vinNumber' element={<Util />} />
              <Route path="violations/:plateNumber" element={ <Rexgen />} />
              {/* <Route path="violations/:plateNumber/evidence/:ticketNumber" element={<RexgenEvidence/>} />
              <Route path="violations/:plateNumber/evidence/:ticketNumber/proof" element={<RexgenProof/>} /> */}
              <Route path="service/:merchantId/:catId/:serviceId" element={ <ServiceForm />} />
              <Route path="gn/:formId" element={ <GazpromNeft />} />
              <Route path="gn/:formId/:serviceId" element={ <GazpromNeft />} />
              <Route path="csinvoice/:invoiceId" element={ <CsInvoice/>} />
              <Route path="qr" element={<Qr/>}> </Route>
            </Route>

            <Route path="invoiceview/:invoiceId" element={<ReceiptForm />} />

            <Route path="/" element={<MainLayout />}>
              <Route index element={<Government />} />  
              <Route path="gover" element={<Government />} />
              <Route path="business" element={<Buziness />} />
              <Route path="cafe" element={<Cafedemo />} />
            </Route>
            <Route path="/" element={<SecondLayout />}>
              <Route path="tm/receipt/:invoiceId" element={<Receipt />} />
              <Route path="receipt/:invoiceId" element={<ReceiptF />} />
              {/*<Route path="demostore" element={<Nikestore />} />
              <Route path="democafe" element={<Cafedemo />} />
    <Route path="demogov" element={<Egovdemo />} />*/}

              <Route
                path="*"
                element={<Notfound status={{ status: "404" }} />}
              />
            </Route>
          </Routes>
        </div>
      </>
    );
  }
}

/*function Login() {
  window.location.replace("https://app.smartpay.tj");
  return null;
}*/
